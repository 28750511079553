import React, { useState } from 'react';
import AssignBlock, { OnChangeProps } from '~/components/organism/AssignBlock';
import Button from '~/components/atom/Button';
import JustificationContainer from '~/components/atom/JustificationContainer';
import { Heading4, Variant } from '~/components/atom/Typography';

const text = {
  heading: 'Contacten toewijzen aan',
};

export type Props = {
  loading?: boolean;
  usedOffices: Record<string, string>;
  /** The direction of the assign block */
  direction?: 'row' | 'column';
  onSave: (update: OnChangeProps) => void;
  /** Updates the used offices in the app */
  onOfficeChange: (officeId: string) => void;
} & OnChangeProps;

const AssignContactWithSave: React.FCC<Props> = ({
  dataTestId,
  userId,
  officeId,
  loading = false,
  usedOffices,
  onSave,
  onOfficeChange,
  direction = 'row',
  ...rest
}) => {
  const [update, setUpdate] = useState<OnChangeProps>({ userId, officeId });
  return (
    <>
      <Heading4 variant={Variant.primary} size="m">
        {text.heading}
      </Heading4>
      <JustificationContainer
        justification="space-between"
        align="end"
        backgroundColor={{ group: 'primary', variant: 'translucent' }}
        border={{ radius: 'base' }}
        padding={['m']}
        direction={direction}
        data-testid={dataTestId}
        width="100%"
        gap="base"
        {...rest}
      >
        <AssignBlock
          userId={update.userId}
          officeId={update.officeId}
          onChange={change => {
            setUpdate(change);
            if (change.officeId) {
              onOfficeChange(change.officeId);
            }
          }}
          disabledOfficeIds={Object.values(usedOffices)}
          direction={direction}
        />
        <Button
          size="medium"
          label="Klaar"
          onClick={() => onSave(update)}
          appearance="secondary"
          icon="check"
          loading={loading}
          disabled={update.userId === userId && update.officeId === officeId}
        />
      </JustificationContainer>
    </>
  );
};

export default AssignContactWithSave;
