import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Dropdown from '~/components/molecule/Dropdown';
import Loading from '~/components/atom/Loading';
import {
  FlowV2_Action_Realworks_SendContactFragment,
  useGetAppStatusRealworksQuery,
} from '~/graphql/types';
import useCurrentAccount from '~/hooks/useCurrentAccount';
import ErrorScreen from '~/components/page/ErrorScreen';
import getActiveTokenOptions from '~/util/getActiveTokenOptions';
import { Props as FormProps } from '../ActionForm';
import TEST_ID from './index.testid';
import JustificationContainer from '~/components/atom/JustificationContainer';
import Catalog from '~/Catalog';
import InputLabelWithHelpLink from '~/components/molecule/InputLabelWithHelpLink';
import ToggleCheckbox from '~/components/molecule/ToggleCheckbox';
import hasValue from '~/util/hasValue';

export type Props = FormProps & {
  dataTestId?: string;
  action: FlowV2_Action_Realworks_SendContactFragment;
};

const text = {
  dropdownLabel: 'Selecteer een vestiging',
  toggleLabel: 'Stuur naar de vestiging van het contact',
  extraInfo: '(indien het contact geen vestiging heeft)',
  helpLink: 'https://help.dathuis.nl/nl/articles/5447060-over-de-realworks-app',
};

const RealworksSendContact: React.FCC<Props> = ({ action, onChange }) => {
  const [sendToContactOwner, setSendToContactOwner] = useState<boolean>(
    action.sendToContactOwner || false,
  );

  const [tokenContainerId, setTokenContainerId] = useState<
    FlowV2_Action_Realworks_SendContactFragment['tokenContainerId']
  >(action.tokenContainerId);

  const { id: accountId } = useCurrentAccount();

  const { data, loading } = useGetAppStatusRealworksQuery({
    variables: {
      accountId,
    },
  });

  const realworksStatus = data?.getAppStatusRealworks;

  useEffect(() => {
    onChange({
      ...action,
      tokenContainerId,
      sendToContactOwner,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenContainerId, sendToContactOwner]);

  if (!data || !realworksStatus) return <ErrorScreen />;
  if (loading) return <Loading />;

  const options = getActiveTokenOptions(realworksStatus);

  return (
    <Container>
      <JustificationContainer direction="column" gap="base">
        <ToggleCheckbox
          label={
            <InputLabelWithHelpLink
              label={text.toggleLabel}
              help={{ link: text.helpLink }}
            />
          }
          value={sendToContactOwner}
          onChange={() => setSendToContactOwner(!sendToContactOwner)}
          containerProps={{
            direction: 'row',
            align: 'center',
            justification: 'space-between',
            width: '100%',
          }}
        />
        <Dropdown
          options={options}
          onChange={e => {
            setTokenContainerId(e.option.payload || null);
          }}
          selectedOptionIdx={options.findIndex(
            opt => opt.payload == tokenContainerId,
          )}
          dataTestId={TEST_ID.TOKEN_CONTAINER_DROPDOWN}
          label={`${text.dropdownLabel} ${
            sendToContactOwner ? text.extraInfo : ''
          }`}
          required
          error={hasValue(tokenContainerId) ? undefined : Catalog.requiredField}
        />
      </JustificationContainer>
    </Container>
  );
};

const Container = styled.div<{}>(
  ({ theme }) => css`
    margin: ${theme.space('xxs')} 0;
  `,
);

export default RealworksSendContact;
